import React, { useEffect } from "react";
import "./landing.css";
import Typewriter from "typewriter-effect";
import ParticleBackground from "../../particleBackground";
import Aos from "aos";
import "aos/dist/aos.css";
import discord from "../../images/dc.png";
// import devfolio from "../../images/devfolio_logo.png";
import Timer from "../timer/timer";
const Landing = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    const script = document.createElement("script");
    script.src = "https://apply.devfolio.co/v2/sdk.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div id='hero' className='landing-main'>
        {/* <a className="atag" href='#about'>
          <div data-aos='fade-up' className='hvr-shutter-in-vertical name'>
            {" "}

            REVA Hack{" "}
          </div>
        </a> */}
        {/* <div className='float'> */}

        <div className='sm-headings'>
          <h4 className='flux'>48 Hours Hybrid</h4>
          <h4 className='flux'>International Hackathon</h4>
        </div>
        {/* </div> */}
        <h1 data-aos='fade-up' className='test'>
          REVA HACK
        </h1>
        <div data-aos='fade-up' className='name-2'>
          <Typewriter
            options={{
              strings: ["Innovate For a Better Tomorrow", "The Hack Is Back"],
              autoStart: true,
              loop: true,
            }}
          />
        </div>
        {/* After Coming soon gets over */}
        <div data-aos='fade-up' className='main-buttons'>
          <div
            className='apply-button landing-btn'
            data-hackathon-slug='reva-hack'
            data-button-theme='light'></div>

          {/* <a
            href="https://reva-hack.devfolio.co/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="discord-btn" style={{ letterSpacing: "2px" }}>
              <span>Apply on Devfolio</span>
              <img
                style={{ marginLeft: "10px" }}
                height="30px"
                width="30px"
                src={devfolio}
                alt=""
              />
            </button>
          </a> */}

          <a
            href='https://discord.gg/6Sv9ZdQ4er'
            target='_blank'
            rel='noreferrer'>
            <button className='landing-btn' style={{ letterSpacing: "2px" }}>
              {" "}
              <span>Join Us On</span>
              <img
                style={{ marginLeft: "10px" }}
                height='40px'
                width='40px'
                src={discord}
                alt=''
              />
            </button>
          </a>
        </div>
        <div data-aos='fade-up'>
          <Timer />
        </div>
        <ParticleBackground />
      </div>
    </>
  );
};

export default Landing;
