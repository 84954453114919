import React, { useState } from "react";
import "./timer.css";

const Timer = () => {
  return (
    <>
      <div className="solid active form-box">
        <h1>THE HACK HAS BEGUN</h1>
      </div>
    </>
  );
};

export default Timer;
